import React, { FC } from "react";
import { TableCell, TableRow, useTheme } from "@mui/material";
import { DataTable, Input } from "@ntpkunity/controls";
import { Controller } from "react-hook-form";
import { BorderedTableWrap, MultiSelectAll } from "@sharedComponents";
import { IFPCategoryMappingProps } from "Interface";
import { FinancialInsuranceCategory } from "Enums";

const FinancialInsuranceTable: FC<IFPCategoryMappingProps> = ({
  form,
  setUpdatedFPCategoryRows,
  financeTypes,
  fpCategoryMappings,
}) => {
  const theme = useTheme();
  const { setValue, getValues, control } = form;

  const financeTypeItems =
    financeTypes?.map((finance_type) => ({
      text: finance_type.name,
      value: finance_type.id,
    })) || [];

  const categories = Object.values(FinancialInsuranceCategory).map((value) => ({
    text: value,
    value: value,
  }));

  const handleRowChange = (index) => {
    setUpdatedFPCategoryRows((prev) => new Set(prev).add(index));
  };

  return (
    <BorderedTableWrap theme={theme} className="custom-table-wrap">
      <DataTable
        theme={theme}
        variant="basic"
        theadChildren={
          <>
            <TableRow>
              <TableCell className="sr-num"></TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Finance Type</TableCell>
              <TableCell>External Code</TableCell>
            </TableRow>
          </>
        }
        tbodyChildren={categories?.map((item, index) => {
          return (
            <React.Fragment key={index + 1}>
              <TableRow className="child-tr">
                <TableCell className="sr-num">{index + 1}</TableCell>
                <TableCell>
                  <Controller
                    name={`fpMapping.${index}.category`}
                    control={control}
                    defaultValue={item.text}
                    render={({ field: { value, onChange } }) => (
                      <Input
                        theme={theme}
                        fullWidth={true}
                        placeholder={"Type here..."}
                        type="string"
                        id={`fpMapping.${index}.category`}
                        value={value?.trimStart()}
                      ></Input>
                    )}
                  />
                </TableCell>
                <Controller
                  name={`fpMapping.${index}.id`}
                  control={control}
                  defaultValue={
                    fpCategoryMappings?.data?.find(
                      (record) => record.category === item.text
                    )?.id
                  }
                  render={({ field }) => (
                    <input
                      type="hidden"
                      {...field}
                      value={
                        field.value
                          ? field.value
                          : fpCategoryMappings?.data?.find(
                              (record) => record.category === item.text
                            )?.id
                      }
                    />
                  )}
                />
                <TableCell className="input-cell">
                  <Controller
                    name={`fpMapping.${index}.finance_type`}
                    control={control}
                    defaultValue={
                      fpCategoryMappings?.data?.find(
                        (record) => record.category === item.text
                      )?.finance_type
                    }
                    render={({ field: { value, onChange } }) => (
                      <MultiSelectAll
                        id={`fpMapping.${index}.finance_type`}
                        name={`fpMapping.${index}.finance_type`}
                        theme={theme}
                        isSelectAll={false}
                        getValues={getValues}
                        setValue={setValue}
                        items={financeTypeItems}
                        value={
                          value
                            ? (value as any[])
                            : fpCategoryMappings?.data?.find(
                                (record) => record.category === item.text
                              )?.finance_type
                        }
                        onChange={(e) => {
                          onChange(e);
                          handleRowChange(index);
                        }}
                        disablePortal={false}
                      />
                    )}
                  />
                </TableCell>
                <TableCell className="input-cell">
                  <Controller
                    name={`fpMapping.${index}.external_code`}
                    control={control}
                    defaultValue={
                      fpCategoryMappings?.data?.find(
                        (record) => record.category === item.text
                      )?.external_code
                    }
                    render={({ field: { value, onChange } }) => (
                      <Input
                        theme={theme}
                        fullWidth={true}
                        placeholder={"Type here..."}
                        type="string"
                        id={`fpMapping.${index}.external_code`}
                        onChange={(newValue) => {
                          onChange(newValue);
                          handleRowChange(index);
                        }}
                        value={
                          value
                            ? value?.trimStart()
                            : fpCategoryMappings?.data?.find(
                                (record) => record.category === item.text
                              )?.external_code
                        }
                      ></Input>
                    )}
                  />
                </TableCell>
              </TableRow>
            </React.Fragment>
          );
        })}
      />
    </BorderedTableWrap>
  );
};

export default FinancialInsuranceTable;
